import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { loadCustomerList } from "../../actions/AdminCustomerActions";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { formatMerchantType } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { toCustomerFilter } from "../../helpers/CustomerFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  CUSTOMER_CREATE_URL,
  CUSTOMER_ITEM_URL,
  CUSTOMER_LIST_ACTIVE_URL,
  CUSTOMER_LIST_ALL_URL,
  CUSTOMER_LIST_INACTIVE_URL,
} from "../../constants/AdminPathConstants";
import CustomerFilterTypes from "../../constants/CustomerFilterTypes";
import AdminCustomerFilterDialogWrapper from "../../wrappers/admin/AdminCustomerFilterDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import RoutingTabs from "../../components/deprecated/RoutingTabs";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import {
  CREATE_CUSTOMER_CSV_URL,
  CREATE_CUSTOMER_DPS_CSV_URL,
} from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import AvatarWithName from "../../components/avatars/AvatarWithName";

const CUSTOMER_FILTER_DIALOG_HASH = "#CFDH";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
    appBarRightActionButton: {
      color: "#fff",
      paddingTop: 0,
      "& svg": {
        color: "#fff",
      },
    },
  }),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { loadCustomerList },
  ),
  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toCustomerFilter))
      .withLatestFrom(propsStream)
      .map(
        ([filter, props]) =>
          filter
            .setValue(
              "status",
              CustomerFilterTypes.get(
                props.params.statusCode,
                CustomerFilterTypes.first(),
              ),
            )
            .setValue("use_solr", true),
        // .setValue("hasEmail", true),
      )
      .distinctUntilChanged(isEqualData);

    const customerListResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        props.loadCustomerList(filter).catch(() => Observable.of({})),
      )
      .map(response => fromJS(response))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        filterStream,
        customerListResponseStream,
        (props, filter, customerList) => ({
          ...props,
          filter,
          list: customerList.getIn(["payload", "data", "list"], List()),
          total: customerList.getIn(["payload", "data", "total"], 0),
          isLoading: customerList.get("pending", false),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminCustomerList.propTypes = {
  classes: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),

  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  setLocation: PropTypes.func.isRequired,
};

function AdminCustomerList(props) {
  const { getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      slug="customers"
      title={getLocalisationMessage("customers", "Customers")}
    >
      <Redirect
        to={CUSTOMER_LIST_ALL_URL}
        when={!CustomerFilterTypes.has(props.params.statusCode)}
      />

      <AdminCustomerFilterDialogWrapper
        open={props.location.hash === CUSTOMER_FILTER_DIALOG_HASH}
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <RoutingTabs
        width={300}
        tabs={[
          {
            label: getLocalisationMessage("all", "All"),
            value: CUSTOMER_LIST_ALL_URL,
          },
          {
            label: getLocalisationMessage("active", "Active"),
            value: CUSTOMER_LIST_ACTIVE_URL,
          },
          {
            label: getLocalisationMessage("inactive", "Inactive"),
            value: CUSTOMER_LIST_INACTIVE_URL,
          },
        ]}
        omitQueryParams={["page"]}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={9}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <FlexBox>
            <CustomButton
              onClick={() => props.setLocation(CUSTOMER_CREATE_URL)}
              startIcon={<Add />}
              variant={CONTAINED}
              color={SECONDARY}
              style={{ borderRadius: "20px" }}
            >
              {props.getLocalisationMessage("create_new", "Create New")}
            </CustomButton>
            <Link to={updateHash(props.location, CUSTOMER_FILTER_DIALOG_HASH)}>
              <Tooltip title={getLocalisationMessage("filter", "Filter")}>
                <IconButton>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Link>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_CUSTOMER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_CUSTOMER_DPS_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("customer_dps_csv", "Customer DPS CSV")}
              </MenuItem>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(CREATE_CUSTOMER_DPS_CSV_URL, {
                  ...props.filter.getDefinedValues(),

                  is_merchant: true,
                })}
              >
                {getLocalisationMessage("merchant_dps_csv", "Merchant DPS CSV")}
              </MenuItem>
            </MenuButtonMore>
          </FlexBox>
        }
      >
        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          cellRenderer={row => (
            <strong>
              {!props.isAdminViewer ? (
                <Link to={CUSTOMER_ITEM_URL + row.cellData.get("customer_id")}>
                  {row.cellData.get("customer_id")}
                </Link>
              ) : (
                row.cellData.get("customer_id")
              )}
            </strong>
          )}
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("name", "Name")}
          dataKey="last_name"
          disableSort={true}
          cellRenderer={row =>
            [row.cellData.get("first_name"), row.cellData.get("last_name")]
              .filter(Boolean)
              .join(" ")
          }
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("login", "Login")}
          dataKey="login"
          disableSort={true}
          cellRenderer={row => row.cellData.get("login")}
        />

        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("phone", "Phone")}
          dataKey="phone"
          disableSort={true}
          cellRenderer={row => row.cellData.get("phone")}
        />

        <DataListColumn
          width={60}
          justifyContent="center"
          label={getLocalisationMessage("client_type", "Client Type")}
          dataKey="merchant_type"
          disableSort={true}
          cellRenderer={row =>
            formatMerchantType(
              row.cellData.get("merchant_type"),
              getLocalisationMessage,
            )
          }
        />

        <DataListColumn
          width={60}
          justifyContent="center"
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          cellRenderer={row => {
            const status = row.cellData.get("status");
            return getLocalisationMessage(status) || formatText(status);
          }}
        />
        <DataListColumn
          width={94}
          label={getLocalisationMessage("created_by", "Created by")}
          dataKey="created_by"
          disableSort={true}
          cellRenderer={row => {
            const createdByName = row.cellData.get("created_by");

            if (createdByName) {
              return (
                <AvatarWithName
                  name={createdByName}
                  imagePath={row.cellData.get("created_by")}
                >
                  {createdByName}
                </AvatarWithName>
              );
            }

            return getLocalisationMessage("na", "N/A");
          }}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("joined_date", "Joined Date")}
          dataKey="created_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomerList);
