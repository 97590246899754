import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import { types, mapSchema } from "../../shared/helpers/ObjectMapper";

export const toCustomerFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),
    merchantType: types.string,
    status: types.string,
    created_by: types.string,
  }),
);
