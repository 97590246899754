import React, { useEffect, useState } from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText, parseFloat } from "../../helpers/FormatUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { CASH_CLIENTS, MERCHANTS } from "../../constants/MerchantTypes";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import PostcodeAutoCompleteForOrder from "../form/PostcodeAutoCompleteForOrder";
import { getValue } from "../../helpers/DataUtils";
import MuiPhoneNumber from "material-ui-phone-number-uzpost";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import {
  ArrowRightAlt,
  CloudDownload,
  Done,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { CUSTOMER_LIST_ALL_URL } from "../../constants/AdminPathConstants";
import FormDateField from "../form/FormDateField";
import FormCustomToggle from "../form/FormCustomToggleV2";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { createOtpValidator } from "../../helpers/FormUtils";
import { avvalSearchUrl } from "../orders-core/CommissionTINDialog";
import FileUploadButton from "../deprecated/FileUploadButton";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import { formatLocalisedPaymentType } from "../../helpers/OrderHelper";
import FormCheckbox from "../form/FormCheckbox";
import { getAttachmentUrl } from "../../api/shared/FileApi";

const Statuses = OrderedSet([ACTIVE, INACTIVE]);
const valueSelector = formValueSelector("AdminCustomerMerchantForm");

const enhancer = compose(
  connect(
    state => ({
      isRTL: getIsRTL(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage },
  ),
  reduxForm({
    form: "AdminCustomerMerchantForm",
    enableReinitialize: true,
    validate: (values, { getLocalisationMessage, edit }) => ({
      login: !values.login && getLocalisationMessage("this_field_is_required"),
      merchantInn:
        !values.merchantInn && getLocalisationMessage("this_field_is_required"),
      password:
        !edit &&
        !values.password &&
        getLocalisationMessage("this_field_is_required"),
      confirmPassword:
        values.password &&
        values.confirmPassword !== values.password &&
        getLocalisationMessage(
          "passwords_are_not_equal",
          "Passwords are not equal",
        ),
      firstName:
        !values.firstName && getLocalisationMessage("this_field_is_required"),
      phone:
        values.phone &&
        values.phone.length < 8 &&
        getLocalisationMessage("this_field_is_required"),
      merchantType:
        fp.isEmpty(values.merchantType) &&
        getLocalisationMessage("select_merchant_type", "Select Merchant Type"),
    }),
  }),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "jurisdiction",
      "postcode",
      "phone",
      "merchantType",
      "merchant",
      "merchantInn",
      "secondPhone",
      "company",
    ),
  })),
);

const AdminCustomerMerchantForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  values,
  change,
  setLocation,
  setShow,
  ...props
}) => {
  const [postcodeOptions, setPostcodeOptions] = useState([]);
  const { postcode, jurisdiction, phone, secondPhone } = values;
  const jurisdictionId = getValue(jurisdiction, "id");
  const countryCode = getValue(jurisdiction, "country_code");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [error, setError] = useState(null);

  const [contractUrl, setContractUrl] = useState("");
  const [innUrl, setInnUrl] = useState("");

  useEffect(() => {
    if (getValue(values, "company.innAttachment.id")) {
      getAttachmentUrl(getValue(values, "company.innAttachment.id")).then(r => {
        setInnUrl(r.data);
      });
    }
  }, [getValue(values, "company.innAttachment.id")]);

  useEffect(() => {
    if (getValue(values, "company.contractAttachment.id")) {
      getAttachmentUrl(getValue(values, "company.contractAttachment.id")).then(
        r => {
          setContractUrl(r.data);
        },
      );
    }
  }, [getValue(values, "company.contractAttachment.id")]);

  useEffect(() => {
    if (
      values.merchantType === MERCHANTS &&
      values.merchantInn &&
      (values.merchantInn.length === 14 || values.merchantInn.length === 9)
    ) {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_inn_comm: {
            comm_inn: values.merchantInn,
          },
        }),
      };

      fetch(avvalSearchUrl, options)
        .then(response => response.json())
        .then(res => {
          if (res && res.mess === "success") {
            setError(null);
          } else {
            setError(
              getLocalisationMessage("inn_is_not_found", "INN is not found"),
            );
          }
        })
        .catch(err => {
          props.showErrorMessage(err);
        });
    } else {
      setError(getLocalisationMessage("inn_is_not_found", "INN is not found"));
    }
  }, [values.merchantInn]);

  useEffect(() => {
    if (values.merchant) {
      change("merchantType", MERCHANTS);
      if (setShow) {
        setShow(true);
      }
    } else {
      change("merchantType", CASH_CLIENTS);
      if (setShow) {
        setShow(false);
      }
    }
  }, [values.merchant]);

  return (
    <Card style={{ width: "100%" }}>
      <PageLoading isLoading={submitting} />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <FlexBox justify="center" align="center" style={{ marginTop: 13 }}>
          <FormCustomToggle
            disabled={props.edit}
            leftLabel={getLocalisationMessage("cash_client", "Cash Client")}
            rightLabel={getLocalisationMessage("merchant", "Merchant")}
            name="merchant"
          />
        </FlexBox>
        <CardHeader
          title={
            values.merchantType === CASH_CLIENTS
              ? getLocalisationMessage("personal_details", "Personal Details")
              : getLocalisationMessage("company_details", "Company Details")
          }
        />

        <CardContent>
          <FlexBox style={{ gap: 13 }} direction="column">
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FormTextField
                  fullWidth={true}
                  name="merchantInn"
                  label={`${getLocalisationMessage(
                    "corporate_inn_pinfl",
                    "Corporate INN/PINFL",
                  )} *`}
                  type="number"
                  onInput={e => {
                    // eslint-disable-next-line radix
                    e.target.value = e.target.value.toString().slice(0, 14);
                  }}
                  onChange={e => change("login", e.target.value)}
                  validate={createOtpValidator(error)}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  fullWidth={true}
                  name="company.name"
                  label={`${getLocalisationMessage(
                    "company_name",
                    "Company name",
                  )} *`}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormSelectField
                  fullWidth={true}
                  name="status"
                  autoWidth={true}
                  options={Statuses}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  label={`${getLocalisationMessage("status")} *`}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormSelectField
                  fullWidth={true}
                  name="source"
                  autoWidth={true}
                  options={["UZPOST", "HYBRID"]}
                  formatOption={x => getLocalisationMessage(x) || formatText(x)}
                  label={`${getLocalisationMessage(
                    "customer_type",
                    "Customer type",
                  )} *`}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FormJMAutoComplete
                  name="jurisdiction"
                  margin="normal"
                  levelIds={[3]}
                  label={getLocalisationMessage("district", "District")}
                  fullWidth={true}
                  renderOption={option => (
                    <FlexBox direction="column">
                      <span>{option.name}</span>
                      <span
                        style={{
                          fontSize: ".8rem",
                          fontStyle: "italic",
                        }}
                      >
                        {option.value.hierarchy.map((v, i, arr) =>
                          i === arr.length - 1 ? v.name : `${v.name} > `,
                        )}
                      </span>
                    </FlexBox>
                  )}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <PostcodeAutoCompleteForOrder
                  immediatelyShowError={postcode}
                  name="postcode"
                  margin="normal"
                  fullWidth={true}
                  jurisdictionId={jurisdictionId}
                  jurisdiction={jurisdiction}
                  disabled={!jurisdictionId}
                  postcodes={postcodeOptions}
                  getPostcodeOptions={v => setPostcodeOptions(v)}
                  // onChange={(e, v) => setValidationLevel(v, "PVLevel")}
                  label={getLocalisationMessage("index", "Index")}
                  hintText={getLocalisationMessage("type_to_search")}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="address.address"
                  margin="normal"
                  fullWidth={true}
                  label={getLocalisationMessage("address")}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="company.website"
                  margin="normal"
                  fullWidth={true}
                  label={getLocalisationMessage(
                    "company_website",
                    "Company website",
                  )}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true} style={{ marginTop: "-10px" }}>
              <FlexBox flex={true}>
                <FormTextField
                  name="firstName"
                  margin="normal"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "company_user_name",
                    "Company user name",
                  )} *`}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="lastName"
                  margin="normal"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "company_user_last_name",
                    "Company user last name",
                  )} *`}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <MuiPhoneNumber
                  disableAreaCodes={true}
                  margin="normal"
                  isValid={value => value.length > 8}
                  defaultCountry={
                    countryCode ? countryCode.toLowerCase() : "uz"
                  }
                  name="phone"
                  value={phone || ""}
                  onChange={v => {
                    change("phone", v);
                  }}
                  variant={OUTLINED}
                  size="small"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "phone_number",
                    "Phone Number",
                  )}*`}
                />
                <FormTextField
                  style={{ display: "none" }}
                  name="phone"
                  label={getLocalisationMessage("phone_number", "Phone Number")}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <MuiPhoneNumber
                  disableAreaCodes={true}
                  margin="normal"
                  defaultCountry={
                    countryCode ? countryCode.toLowerCase() : "uz"
                  }
                  name="secondPhone"
                  value={secondPhone || ""}
                  onChange={v => {
                    change("secondPhone", v);
                  }}
                  variant={OUTLINED}
                  size="small"
                  fullWidth={true}
                  label={getLocalisationMessage(
                    "second_phone_number",
                    "Second Phone Number",
                  )}
                />
                <FormTextField
                  style={{ display: "none" }}
                  name="secondPhone"
                  label={getLocalisationMessage(
                    "second_phone_number",
                    "Phone Number",
                  )}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormTextField
                  name="email"
                  margin="normal"
                  fullWidth={true}
                  label={getLocalisationMessage("email")}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FormTextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  fullWidth={true}
                  label={`${getLocalisationMessage("password", "Password")} *`}
                  withOutInputProps={false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "confirm_password",
                    "Confirm Password",
                  )} *`}
                  withOutInputProps={false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardHeader title={getLocalisationMessage("contract", "Contract")} />

        <CardContent>
          <FlexBox style={{ gap: 16 }} direction="column" flex={true}>
            <FlexBox style={{ gap: 16 }} flex={true}>
              <FlexBox flex={true}>
                <FileUploadButton
                  style={{ height: 45 }}
                  fullWidth={true}
                  variant="outlined"
                  onChange={fp.flow(fp.get("data"), value =>
                    change("company.contractAttachment", value),
                  )}
                  label={getLocalisationMessage(
                    "upload_contract",
                    "Upload Contract",
                  )}
                />
              </FlexBox>

              {getValue(values, "company.contractAttachment.id") && (
                <FlexBox justify="center" align="center">
                  <ArrowRightAlt fontSize="large" />
                </FlexBox>
              )}

              {getValue(values, "company.contractAttachment.id") && (
                <FlexBox flex={true} justify="center">
                  <FlexBox flex={true} align="center">
                    <Button
                      fullWidth={true}
                      variant="outlined"
                      disabled={true}
                      style={{ height: 45 }}
                    >
                      {getValue(values.company.contractAttachment, "name")}
                    </Button>
                  </FlexBox>

                  <FlexBox justify="flex-end">
                    <IconButton
                      onClick={() => {
                        fetch(contractUrl)
                          .then(response => response.blob())
                          .then(myBlob => {
                            let objectURL = URL.createObjectURL(myBlob);
                            if (myBlob && myBlob.size > 0) {
                              const iframe = document.createElement("iframe");
                              iframe.style.display = "none";
                              document.body.appendChild(iframe);
                              iframe.src = objectURL;
                              objectURL = URL.revokeObjectURL(myBlob);
                              window.setTimeout(() => {
                                iframe.contentWindow.print();
                              }, 1000);
                            }
                          });
                      }}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton href={contractUrl}>
                      <CloudDownload />
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}

              <FlexBox flex={true}>
                <FormDateField
                  fullWidth={true}
                  name="company.contractStartDate"
                  hintText={getLocalisationMessage(
                    "contract_created_date",
                    "Contract created date",
                  )}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormDateField
                  fullWidth={true}
                  name="company.contractEndDate"
                  hintText={getLocalisationMessage(
                    "contract_expiration_date",
                    "Contract expiration date",
                  )}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox style={{ gap: 16 }} flex={true}>
              <FlexBox flex={true}>
                <FileUploadButton
                  style={{ height: 45 }}
                  variant="outlined"
                  fullWidth={true}
                  onChange={fp.flow(fp.get("data"), value =>
                    change("company.innAttachment", value),
                  )}
                  label={getLocalisationMessage("upload_inn", "Upload INN")}
                />
              </FlexBox>

              {getValue(values, "company.innAttachment.id") && (
                <FlexBox
                  justify="center"
                  align="flex-start"
                  style={{ marginTop: 5 }}
                >
                  <ArrowRightAlt fontSize="large" />
                </FlexBox>
              )}

              {getValue(values, "company.innAttachment.id") && (
                <FlexBox align="flex-start" flex={true}>
                  <FlexBox flex={true} style={{ height: 45 }}>
                    <Button
                      fullWidth={true}
                      variant="outlined"
                      disabled={true}
                      style={{ height: 45 }}
                    >
                      {getValue(values.company.innAttachment, "name")}
                    </Button>
                  </FlexBox>

                  <FlexBox style={{ height: 45 }} justify="flex-end">
                    <IconButton
                      onClick={() => {
                        fetch(innUrl)
                          .then(response => response.blob())
                          .then(myBlob => {
                            let objectURL = URL.createObjectURL(myBlob);
                            if (myBlob && myBlob.size > 0) {
                              const iframe = document.createElement("iframe");
                              iframe.style.display = "none";
                              document.body.appendChild(iframe);
                              iframe.src = objectURL;
                              objectURL = URL.revokeObjectURL(myBlob);
                              window.setTimeout(() => {
                                iframe.contentWindow.print();
                              }, 1000);
                            }
                          });
                      }}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton href={innUrl}>
                      <CloudDownload />
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}

              <FlexBox direction="column" flex={true}>
                <FormChipAutoComplete
                  name="company.paymentTypes"
                  options={OrderPaymentTypes}
                  fullWidth={true}
                  formatOption={x =>
                    formatLocalisedPaymentType(x, getLocalisationMessage)
                  }
                  hintText={getLocalisationMessage("type_here_to_search")}
                  label={`${getLocalisationMessage("payment_method")} *`}
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <FormTextField
                  name="company.creditLimit"
                  fullWidth={true}
                  parseOnBlur={parseFloat}
                  disabled={values.company.unlimited}
                  label={getLocalisationMessage("credit_limit", "Credit Limit")}
                />

                <FormCheckbox
                  name="company.unlimited"
                  label={getLocalisationMessage("unlimited", "Unlimited")}
                  onChange={() => change("company.creditLimit", null)}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardHeader
          title={getLocalisationMessage("company_endpoint", "Company Endpoint")}
        />

        <CardContent>
          <FlexBox direction="column" gutter={16}>
            <FlexBox>
              <FormTextField
                name="company.endpoint"
                fullWidth={true}
                label={getLocalisationMessage("end_point", "End Point")}
              />
            </FlexBox>
            <FlexBox gutter={16}>
              <FlexBox flex={true}>
                <FormTextField
                  name="key1"
                  fullWidth={true}
                  label={getLocalisationMessage("header_key", "Header key")}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="key2"
                  fullWidth={true}
                  label={getLocalisationMessage("header_value", "Header value")}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardHeader
          title={getLocalisationMessage(
            "additional_settings",
            "Additional Settings",
          )}
        />

        <CardContent>
          <FlexBox direction="column" gutter={16}>
            <FlexBox flex={true}>
              <FormCheckbox
                name="verificationRequired"
                label={getLocalisationMessage(
                  "face_id_verification",
                  "Delivery verification with FACE ID",
                )}
              />
            </FlexBox>
            <FlexBox flex={true}>
              <FormCheckbox
                name="canCreateCodOrder"
                label={getLocalisationMessage(
                  "can_create_order_with_cod",
                  "Can create order with COD",
                )}
              />
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox
            style={{ marginBottom: "1rem" }}
            flex={true}
            justify="flex-end"
          >
            <CustomButton
              style={{ marginRight: ".5rem" }}
              onClick={() => setLocation(CUSTOMER_LIST_ALL_URL)}
              startIcon={<KeyboardArrowLeft />}
              variant={CONTAINED}
              color={SECONDARY}
            >
              {getLocalisationMessage("back", "Back")}
            </CustomButton>
            <CustomButton
              style={{ marginLeft: ".5rem" }}
              variant={CONTAINED}
              color={SECONDARY}
              endIcon={<Done />}
              type="submit"
              onClick={handleSubmit}
            >
              {getLocalisationMessage("save")}
            </CustomButton>
          </FlexBox>
        </CardActions>
      </form>
    </Card>
  );
};
AdminCustomerMerchantForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  showErrorMessage: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
  setLocation: PropTypes.func,
  edit: PropTypes.func,
  setShow: PropTypes.func,
};
export default enhancer(AdminCustomerMerchantForm);
